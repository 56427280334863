import { useFlags } from 'launchdarkly-react-client-sdk';

// BEFORE CREATING A FLAG, READ FOR BEST PRACTICES:
// https://www.flagship.io/feature-flags-naming-conventions/
// https://docs.launchdarkly.com/guides/best-practices/creating-flags

// Flag naming convention:
// feature_{release/op/experiment/permission}_team_month_year{_temp, if temp}

// Do not use dots in the name, while allowed in LD,
// you cannot access flag with dots in the name in react/js
// We truned off auto camel casing on the provider, more here
// https://docs.launchdarkly.com/sdk/client-side/react/react-web#flag-keys

// Launch Darkly Up Time - Plan your default and failure states well:
// We cannot expect 100% uptime from launch darkly. Plan default values and error handling in implementation
// carefully to plan for cases when we may not be able to reach launch darkly to retrieve the flag value.

// WARNING: Launch Darkly will not have 100% uptime. We cannot guarantee a flag value is returned every time
// or that we will not receive the default flag value. Plan you default and failure states for flags and
// implementation very carefully. Think about, what happens if this flag value returns null, or the default
// specified? How will users be impacted? Manage and design for those cases.

// Type of flags:
// RELEASE: a flag used to roll out a release (enable or disable), will be temporary
// OP: operations flag used to turn on and off operations tooling, may or may not be temporary
// EXPERIMENT: A/B and other testing flags, will likely be temporary
// PERMISSION: flag to control feature permissions, eg Only enterprise users have feature XYZ, will not be temporary

// Usage eg:
// import { useFeatureFlag, FLAGS } from 'utils/launchdarkly/flags'
// const isTestFlagEnabled = useFeatureFlag(FLAGS.TEST);
export function useFeatureFlag(flag) {
  try {
    const flagValue = useFlags()[flag.name];
    return typeof flagValue === 'undefined' ? flag.default : flagValue;
  } catch (err) {
    console.error('error from Launch Darkly', err);
    return flag?.default || false;
  }
}
// For temporary flags, like release flags, it is considered best practice to post a branch with
// the flag removed so that flag cleanup is as easy as posssible.

export const FLAGS = {
  // TEMPLATE: {
  //  name        : 'feature_{release/op/experiment/permission}_team_month_year{_temp, if temp}',
  //  description : 'Feature flag to allow enterprise customers access to enterprise feautres',
  //  default     : 'false',
  //  values      : ['true', 'false'],
  //  useFlag     : () => useFlags().<TEMPLATE.name>,
  // },
  // TEST: {
  //  name        : 'test_mxo_12_21_temp',
  //  description : 'Feature flag to test flagging wrapper',
  //  default     : 'false',
  //  values      : ['true', 'false'],
  //  useFlag     : () => useFlags().test_mxo_12_21_temp,
  // },
  MEMBER_MERGE: {
    name: 'feature_member_merge_admin_release_cx_5_23_temp',
    description: 'Release feature flag to allow admins to merge members',
    default: 'false',
    values: ['true', 'false'],
    useFlag: () => useFlags().feature_member_merge_admin_release_cx_5_23_temp,
  },
  MEMBER_DEACTIVATION_ZONE: {
    name: 'member_deactivation_zone_feature_pfrm_08_22_temp',
    description: 'A temporary flag to display danger zone for member deactivation',
    default: 'false',
    values: ['true', 'false'],
    useFlag: () => useFlags().member_deactivation_zone_feature_pfrm_08_22_temp,
  },
  CHANNEL_PARTNERS_PORTAL: {
    name: 'channel_partners_portal_08_02_temp',
    description: 'Toggles Channel Partners tile in Admin',
    default: 'false',
    values: ['true', 'false'],
    useFlag: () => useFlags().channel_partners_portal_08_02_temp,
  },
  TRIALS: {
    name: 'trials_release_cx_09_22_temp',
    description: 'Temporary flag to show/hide the trials on boarding/off boarding flow.',
    default: 'false',
    values: ['true', 'false'],
    useFlag: () => useFlags().trials_release_cx_09_22_temp,
  },
  ADMIN_PORTAL_COMMS_RELEASE: {
    name: 'admin_portal_channel_comms_release_nbd_02_07_temp',
    description: 'release flag for admin portal release flag',
    default: 'false',
    values: ['true', 'false'],
    useFlag: () => useFlags().admin_portal_channel_comms_release_nbd_02_07_temp,
  },
  CANCEL_APPOINTMENT: {
    name: 'appointment_unhappy_path_12_28_temp',
    description: 'Temporary flag to enable/disable the cancel appointment modal.',
    default: 'false',
    values: ['true', 'false'],
    useFlag: () => useFlags().appointment_unhappy_path_12_28_temp,
  },
  MAINTENANCE_MODE: {
    name: 'maintenance_mode_release_pfrm_03_2023_temp',
    description: 'Manually control maintenance polling',
    default: 'false',
    values: ['true', 'false'],
    useFlag: () => useFlags().maintenance_mode_release_pfrm_03_2023_temp,
  },
  TABLE_REDESIGN_ALL: {
    name: 'feature_table_redesign_all_platform_07_23_temp',
    description: 'table redesign',
    default: 'false',
    values: ['true', 'false'],
    useFlag: () => useFlags().feature_table_redesign_all_platform_07_23_temp,
  },
  BOA_PILOT_PROGRAM: {
    name: 'boa_pilot_mxo_08_2023',
    description: 'Bank of America pilot to create covered lives without a census',
    default: 'false',
    values: ['true', 'false'],
    useFlag: () => useFlags().boa_pilot_mxo_08_2023,
  },
  TABLE_REDESIGN_PROVIDERS: {
    name: 'feature_table_redesign_providers_platform_01_24_temp',
    description: 'table redesign - providers',
    default: false,
    values: [true, false],
    useFlag: () => useFlags().feature_table_redesign_providers_platform_01_24_temp,
  },
  TABLE_REDESIGN_COVERED_LIVES: {
    name: 'feature_table_redesign_platform_covered_lives_04_09_temp',
    description: 'table redesign - covered lives',
    default: 'false',
    values: ['true', 'false'],
    useFlag: () => useFlags().feature_table_redesign_platform_covered_lives_04_09_temp,
  },
  TABLE_REDESIGN_ASSESSMENTS: {
    name: 'feature_table_redesign_assessments_platform_01_24_temp',
    description: 'table redesign - assessments',
    default: false,
    values: [true, false],
    useFlag: () => useFlags().feature_table_redesign_assessments_platform_01_24_temp,
  },
  TABLE_REDESIGN_USERS: {
    name: 'feature_table_redesign_users_platform_02_22_temp',
    description: 'table redesign - users',
    default: false,
    values: ['true', 'false'],
    useFlag: () => useFlags().feature_table_redesign_users_platform_02_22_temp,
  },
  RECS_M2: {
    name: 'admin_recommendations_m2_mxa_01_24_temp',
    description: 'Use new recs m1 algorithm to generate therapist recommendations',
    default: false,
    values: ['true', 'false'],
    useFlag: () => useFlags().admin_recommendations_m2_mxa_01_24_temp,
  },
  COHORT_MD_EDITOR: {
    name: 'feature_cohort_md_editor_platform_10_23_temp',
    description: 'New MD editor on cohort administration notes',
    default: false,
    values: [true, false],
    useFlag: () => useFlags().feature_cohort_md_editor_platform_10_23_temp,
  },
  NEW_BROWSE_THERAPISTS: {
    name: 'release_new_browse_therapists_pfrm_2024-02-09',
    default: false,
    values: [true, false],
    useFlag: () => useFlags()['release_new_browse_therapists_pfrm_2024-02-09'],
  },
  REDIRECT_COMPASS_STANDALONE: {
    name: 'redirect_compass_standalone_cgxp_1902-2024-02-27',
    default: false,
    values: [true, false],
    useFlag: () => useFlags()['redirect_compass_standalone_cgxp_1902-2024-02-27'],
  },
  COHORT_PLAN_RATE: {
    name: 'remove_plan_rate_release_lob_1_4_2024_temp',
    default: false,
    values: [true, false],
    useFlag: () => useFlags()['remove_plan_rate_release_lob_1_4_2024_temp'],
  },
  ALLOW_CRISIS_SUPPORT_NUMBER: {
    name: 'global_one_release_lob_3_4_2024_temp',
    default: false,
    values: [true, false],
    useFlag: () => useFlags()['global_one_release_lob_3_4_2024_temp'],
  },
  FILTER_BY_PROVIDER_TAGS: {
    name: 'release_browse_by_provider_tags_pfrm_2024_05_22_temp',
    default: false,
    values: [true, false],
    useFlag: () => useFlags()['release_browse_by_provider_tags_pfrm_2024_05_22_temp'],
  },
  RELEASE_NEW_ASSIGN_PROVIDER_MODAL: {
    name: 'release_new_assign_provider_modal_pfrm_2024_06_temp',
    default: false,
    values: [true, false],
    useFlag: () => useFlags()['release_new_assign_provider_modal_pfrm_2024_06_temp'],
  },
  SHOW_CONNECTED_BENEFITS: {
    name: 'connected_benefits_mxb_07_24_temp',
    default: false,
    values: [true, false],
    useFlag: () => useFlags().connected_benefits_mxb_07_24_temp,
  },
  SHOW_SDOH_ASSESSMENT_PERMISSION: {
    name: 'show_sdoh_assessment_permission_mxb_07_24_temp',
    default: false,
    values: [true, false],
    useFlag: () => useFlags()['show_sdoh_assessment_permission_mxb_07_24_temp'],
  },
  DEACTIVATIONS: {
    name: 'feature_deactivation_platform_07_24',
    default: false,
    values: [true, false],
    useFlag: () => useFlags().feature_deactivation_platform_07_24,
  },
  PROVIDER_HOLD_STATUSES: {
    name: 'care_provider_general_and_quality_hold',
    description: 'Feature flag for new hold statuses for care providers (general and quality)',
    default: 'false',
    values: ['true', 'false'],
    useFlag: () => useFlags().care_provider_general_and_quality_hold,
  },
  SHOW_COMMUNITY_CARE_SOLUTION: {
    name: 'feature_community_care_solution_platform_08_24_temp',
    default: false,
    values: [true, false],
    useFlag: () => useFlags().feature_community_care_solution_platform_08_24_temp,
  },
  SHOW_SPECIALTY_CARE_NAVIGATOR: {
    name: 'speacialty_care_navigator_release_admin_sudbhb_53_2024_temp',
    default: false,
    values: [true, false],
    useFlag: () => useFlags().speacialty_care_navigator_release_admin_sudbhb_53_2024_temp,
  },
  REMOVE_ACCESS_MODAL: {
    name: 'feature_remove_user_access_08_24_platform_temp',
    default: false,
    values: [true, false],
    useFlag: () => useFlags().feature_remove_user_access_08_24_platform_temp,
  },
  SHOW_PEER_RECOVERY: {
    name: 'peer_recovery_release_admin_sudbhb_07_2024_temp',
    default: false,
    values: [true, false],
    useFlag: () => useFlags().peer_recovery_release_admin_sudbhb_07_2024_temp,
  },
  SHOW_GATED_BOOKING_TOGGLE: {
    name: 'gated_booking_toggle_release_mfopx_09_23_temp',
    default: false,
    values: [true, false],
    useFlag: () => useFlags().gated_booking_toggle_release_mfopx_09_23_temp,
  },
  BAMBOO_SHOW_ADMIN_CONTROLS: {
    name: 'bamboo_show_admin_controls',
    description: 'Enable all new controls for Bamboo Admin',
    default: false,
    values: [true, false],
    useFlag: () => useFlags().bamboo_show_admin_controls,
  },
  NEW_PROVIDER_FORM: {
    name: 'release_new_provider_form_pfrm_2024_08_temp',
    default: false,
    values: [true, false],
    useFlag: () => useFlags().release_new_provider_form_pfrm_2024_08_temp,
  },
  BULK_CLONE_PLAN_YEARS: {
    name: 'plan_year_bulk_cloning_core_2024_09_temp',
    default: false,
    values: [true, false],
    useFlag: () => useFlags().plan_year_bulk_cloning_core_2024_09_temp,
  },
};
